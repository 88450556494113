import { yupResolver } from '@hookform/resolvers/yup'
import {
  Circle,
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import * as yup from 'yup'

import config from '../../utils/react-google-maps/config.json'

import { debounce } from 'lodash'
import Button from '../../components/Button'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Spinner from '../../components/Spinner'
import Sidepanel from '../../layouts/Sidepanel'
import Api from '../../services/Api'

const containerStyle = {
  width: '80vw',
  height: '60vh',
}

const vehicleOptions = [
  {
    label: 'Bike',
    value: 'bike',
  },
  {
    label: 'Motorcycle',
    value: 'motorcycle',
  },
  {
    label: 'Sedan',
    value: 'sedan',
  },
  {
    label: 'MPV',
    value: 'mpv',
  },
  {
    label: 'SUV',
    value: 'suv',
  },
  {
    label: 'Truck',
    value: 'truck',
  },
]

const RegisterRider = () => {
  const [radius, setRadius] = useState(3)
  const [center, setCenter] = useState({
    lat: 14.676208,
    lng: 121.043861,
  })
  const [businessZone, setBusinessZone] = useState()
  const [isPlateNumberVisible, setIsPlateNumberVisible] = useState(false)
  const [vehicleType, setVehicleType] = useState()
  const [searchBox, setSearchBox] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const circleRef = useRef()
  const mapRef = useRef()

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        full_name: yup.string().required('Full name is required'),
        email: yup
          .string()
          .email('Invalid email')
          .required('Email is required'),
        phone_number: yup
          .string()
          .required('Phone number is required')
          .matches(
            /^\+[0-9]{12}$/,
            'Phone number must be formatted with prefix (e.g. +63XXXXXXXXXX)'
          ),
        vehicle_type: yup.string().required('Vehicle type is required'),
        vehicle: yup.string().required('Vehicle is required'),
        plate_number:
          vehicleType !== 'bike'
            ? yup.string().required('Plate Number is required')
            : yup.string(),
        password: yup.string().required('Password is required'),
      })
    ),
    defaultValues: {
      full_name: '',
      email: '',
      phone_number: '',
      vehicle_type: '',
      vehicle: '',
      plate_number: '',
      password: '',
    },
  })

  const {
    full_name = '',
    email = '',
    phone_number = '',
    vehicle_type = '',
    vehicle = '',
    plate_number = '',
    password = '',
  } = watch()

  useEffect(() => {
    setVehicleType(vehicle_type)
  }, [vehicle_type])

  const fitBounds = useCallback(
    debounce(() => {
      const bounds = circleRef.current?.state.circle.getBounds()
      if (!bounds) return
      mapRef.current.state.map.fitBounds(bounds)
    }, 300),
    []
  )

  const onPlacesChanged = () => {
    const result = searchBox.getPlaces()[0]
    setCenter({
      lat: result?.geometry.location.lat(),
      lng: result?.geometry.location.lng(),
    })

    const {
      city,
      state,
      country,
      postal_code,
    } = result?.address_components.reduce((acc, component) => {
      if (component.types.includes('locality')) acc.city = component.long_name
      else if (component.types.includes('administrative_area_level_1'))
        acc.state = component.long_name
      else if (component.types.includes('country'))
        acc.country = component.long_name
      else if (component.types.includes('postal_code'))
        acc.postal_code = component.long_name
      return acc
    }, {})

    setBusinessZone({
      address: {
        city: city || '',
        country: country || '',
        province: state || '',
        postal_code: postal_code && postal_code.length ? postal_code : '',
        full_address: result?.formatted_address || '',
        name: result?.name || '',
        vicinity: result?.vicinity || '',
        place_id: result?.place_id || '',
        latitude: result?.geometry.location.lat(),
        longitude: result?.geometry.location.lng(),
        plus_code: result?.plus_code || '',
      },
    })
  }

  const onSBLoad = ref => {
    setSearchBox(ref)
  }

  const onLoad = React.useCallback(
    function callback(map) {
      fitBounds()
    },
    [fitBounds]
  )

  useEffect(() => {
    fitBounds()
  }, [radius, center, fitBounds])

  useEffect(() => {
    if (vehicle_type !== 'bike') {
      setIsPlateNumberVisible(true)
    } else {
      setIsPlateNumberVisible(false)
    }
  }, [vehicle_type])

  const onSubmit = async data => {
    const {
      full_name,
      email,
      phone_number,
      vehicle_type,
      vehicle,
      plate_number,
      password,
    } = data

    setIsLoading(true)

    const body = {
      full_name,
      email,
      phone_number,
      vehicle_type,
      vehicle,
      plate_number,
      password,
      business_zone: {
        address: businessZone?.address,
        enabled: true,
        radius,
      },
      active_status: false,
      receive_updates: false,
      base_fare: 50,
    }

    try {
      const response = await Api.riderSignUp({
        body,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      setValue('full_name', '')
      setValue('email', '')
      setValue('phone_number', '')
      setValue('vehicle_type', '')
      setValue('vehicle', '')
      setValue('plate_number', '')
      setValue('password', '')

      alert('Success!')
    } catch (error) {
      console.log(error)
      alert('Oops! Something went wrong.')
    }

    setIsLoading(false)
  }

  const geocodeCoordinates = (lat, lng, e) => {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyCu10vZtdRHmJ7bxnebSSj7u1LFeMV4GUs`
    fetch(url)
      .then(response => response.json())
      .then(data => {
        const results = data.results
        const {
          city,
          state,
          country,
          postal_code,
        } = results[0].address_components.reduce((acc, component) => {
          if (component.types.includes('locality'))
            acc.city = component.long_name
          else if (component.types.includes('administrative_area_level_1'))
            acc.state = component.long_name
          else if (component.types.includes('country'))
            acc.country = component.long_name
          else if (component.types.includes('postal_code'))
            acc.postal_code = component.long_name
          return acc
        }, {})

        setBusinessZone({
          address: {
            city: city || '',
            country: country || '',
            province: state || '',
            postal_code: postal_code && postal_code.length ? postal_code : '',
            full_address: results[0].formatted_address || '',
            name: results[0].name || '',
            vicinity: results[0].vicinity || '',
            place_id: results[0].place_id || '',
            latitude: e.latLng.lat(),
            longitude: e.latLng.lng(),
            plus_code: results[0].plus_code || '',
          },
        })
      })
      .catch(console.error)
  }

  const canSubmit = useMemo(() => {
    return (
      !!full_name.length &&
      !!email.length &&
      !!phone_number.length &&
      !!vehicle_type.length &&
      !!vehicle.length &&
      !!plate_number.length &&
      !!password.length
    )
  }, [
    full_name.length,
    email.length,
    phone_number.length,
    vehicle_type.length,
    vehicle.length,
    plate_number.length,
    password.length,
  ])

  return (
    <>
      <div className="send-notification-wrapper">
        <div className="panel-wrapper">
          <Sidepanel />
        </div>
        <div className="content">
          <Card>
            <h1>Register a Rider</h1>
            <p>Business Zone:</p>
            <p>
              -&gt;{' '}
              {businessZone
                ? businessZone?.address?.full_address
                : 'Search or Click the location'}
            </p>
            <LoadScript
              id="script-loader"
              googleMapsApiKey={'AIzaSyCu10vZtdRHmJ7bxnebSSj7u1LFeMV4GUs'}
              libraries={config.googleMapsLibraries}>
              <GoogleMap
                ref={mapRef}
                mapContainerStyle={containerStyle}
                center={center}
                options={{
                  mapTypeControl: false,
                  streetViewControl: false,
                  clickableIcons: false,
                }}
                onClick={e => {
                  setCenter({
                    lat: e.latLng.lat(),
                    lng: e.latLng.lng(),
                  })

                  geocodeCoordinates(e.latLng.lat(), e.latLng.lng(), e)
                }}
                zoom={12}
                onLoad={onLoad}>
                <StandaloneSearchBox
                  onPlacesChanged={onPlacesChanged}
                  onLoad={onSBLoad}>
                  <input
                    type="text"
                    placeholder="Type & search a Business Zone here..."
                    style={{
                      boxSizing: `border-box`,
                      border: `1px solid black`,
                      width: `300px`,
                      height: `48px`,
                      padding: `0 12px`,
                      borderRadius: `3px`,
                      boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                      fontSize: `14px`,
                      outline: `none`,
                      textOverflow: `ellipses`,
                      position: 'absolute',
                      left: '50%',
                      marginLeft: '-120px',
                    }}
                  />
                </StandaloneSearchBox>

                <Marker
                  position={{
                    lat: center.lat,
                    lng: center.lng,
                  }}
                />

                <Circle
                  ref={circleRef}
                  center={center}
                  radius={radius * 1000}
                  options={{
                    strokeColor: 'rgba(255,212, 0)',
                    fillColor: 'rgb(255,212,0)',
                    fillOpacity: 0.4,
                    strokeOpacity: 0.4,
                    clickable: false,
                  }}
                />
              </GoogleMap>
            </LoadScript>
            <div>
              <p>
                {radius < 1
                  ? `${radius * 1000} meters`
                  : `${radius} kilometers`}
              </p>
              <Slider
                onChange={setRadius}
                value={radius}
                step={0.1}
                min={0.1}
                max={100}
              />
              <div>
                <form
                  style={{ marginTop: '24px' }}
                  onSubmit={handleSubmit(onSubmit)}>
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type="text"
                          additionalClasses={`${
                            errors.full_name && 'input-error'
                          }`}
                          value={field.value}
                          onChange={field.onChange}
                          inputPlaceholder="Full Name"
                          maxLength={40}
                          children={
                            errors.full_name && (
                              <span className="error-message">
                                {errors.full_name.message}
                              </span>
                            )
                          }
                        />
                      </>
                    )}
                    name={'full_name'}
                  />

                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type="email"
                          value={field.value}
                          additionalClasses={`${errors.email && 'input-error'}`}
                          onChange={field.onChange}
                          inputPlaceholder="Email"
                          maxLength={40}
                          children={
                            errors.email && (
                              <span className="error-message">
                                {errors.email.message}
                              </span>
                            )
                          }
                        />
                      </>
                    )}
                    name={'email'}
                  />
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type="text"
                          value={field.value}
                          additionalClasses={`${
                            errors.phone_number && 'input-error'
                          }`}
                          onChange={field.onChange}
                          inputPlaceholder="Phone Number"
                          maxLength={13}
                          children={
                            errors.phone_number && (
                              <span className="error-message">
                                {errors.phone_number.message}
                              </span>
                            )
                          }
                        />
                      </>
                    )}
                    name={'phone_number'}
                  />
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <Select
                        value={field.value}
                        onChange={field.onChange}
                        additionalClasses={`${
                          errors.vehicle_type && 'input-error'
                        }`}
                        inputPlaceholder="Vehicle Type"
                        children={
                          errors.vehicle_type && (
                            <span className="error-message">
                              {errors.vehicle_type.message}
                            </span>
                          )
                        }
                        options={vehicleOptions}></Select>
                    )}
                    name={'vehicle_type'}
                  />
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          additionalClasses={`${
                            errors.vehicle && 'input-error'
                          }`}
                          inputPlaceholder="Bike/Vehicle Model (e.g. 2024 Toyota Land Cruiser Prado)"
                          maxLength={30}
                          children={
                            errors.vehicle && (
                              <span className="error-message">
                                {errors.vehicle.message}
                              </span>
                            )
                          }
                        />
                      </>
                    )}
                    name={'vehicle'}
                  />
                  {isPlateNumberVisible && (
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <>
                          <Input
                            type="text"
                            value={field.value}
                            onChange={field.onChange}
                            additionalClasses={`${
                              errors.plate_number && 'input-error'
                            }`}
                            inputPlaceholder="Plate Number"
                            maxLength={8}
                            children={
                              errors.plate_number && (
                                <span className="error-message">
                                  {errors.plate_number.message}
                                </span>
                              )
                            }
                          />
                        </>
                      )}
                      name={'plate_number'}
                    />
                  )}
                  <Controller
                    control={control}
                    render={({ field }) => (
                      <>
                        <Input
                          type="password"
                          value={field.value}
                          onChange={field.onChange}
                          additionalClasses={`${
                            errors.password && 'input-error'
                          }`}
                          inputPlaceholder="Set Password"
                          maxLength={16}
                          children={
                            errors.password && (
                              <span className="error-message">
                                {errors.password.message}
                              </span>
                            )
                          }
                        />
                      </>
                    )}
                    name={'password'}
                  />
                  <Button
                    type="submit"
                    className={`btn-yellow send-button ${
                      isLoading ? 'btn-disabled' : ''
                    }`}
                    disabled={isLoading}>
                    {isLoading ? (
                      <Spinner
                        type="Oval"
                        color="#ffd400"
                        height={24}
                        width={24}
                      />
                    ) : (
                      'Register'
                    )}
                  </Button>
                </form>
              </div>
            </div>
          </Card>
        </div>
      </div>
    </>
  )
}

export default RegisterRider
