import React from 'react'

const Select = ({
  name,
  value,
  options,
  onChange,
  inputPlaceholder,
  children,
  additionalClasses,
}) => {
  return (
    <div className="input-holder">
      <select
        className={`input ${additionalClasses}`}
        name={name}
        value={value}
        onChange={onChange}>
        <option>-</option>
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <span className="placeholder">{inputPlaceholder}</span>
      {children}
    </div>
  )
}

export default Select
