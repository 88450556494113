import React, { useContext, useEffect } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import baseAPI from '../services/Api'
import { Context } from '../context'

import CloseIcon from '../assets/images/close-icon.svg'

const Filter = ({ setopenFilter }) => {
  const {
    pendingRequests,
    setPendingRequest,
    setActivePendingRequest,
    types,
    setTypes,
    sort,
    setSort,
  } = useContext(Context)

  const handleFilters = params => {
    if (params === 'new' || params === 'week' || params === 'month') {
      setTypes(params)
    } else {
      setSort(params)
    }
  }

  const debounced = useDebouncedCallback(async () => {
    if (types || sort) {
      const response = await baseAPI.getVerificationRequests({
        type: types,
        sort,
      })

      const newPendingRequests = []
      response.requests.map(request => {
        const cachedRequest = pendingRequests.find(
          pendingRequest => pendingRequest.uid === request.uid
        )

        if (cachedRequest) {
          newPendingRequests.push(cachedRequest)
        } else {
          newPendingRequests.push({
            verification: request,
          })
        }

        return request
      })

      setPendingRequest(newPendingRequests)
      if (newPendingRequests.length) {
        if (newPendingRequests[0].uid) {
          setActivePendingRequest(newPendingRequests[0])
        } else {
          const userResponse = await baseAPI.getUser({
            uid: newPendingRequests[0].verification.uid,
          })
          setActivePendingRequest({
            ...userResponse.data,
            address: userResponse.data.addresses.find(
              address => address.default
            ),
            ...newPendingRequests[0],
          })
        }
      }
    }
  }, 1000)

  useEffect(() => {
    debounced.callback()
  }, [debounced, types, sort])

  return (
    <div className="filter">
      <div className="filter-wrapper">
        <button className="btn close-btn" onClick={() => setopenFilter(false)}>
          <img className="img" src={CloseIcon} alt="" />
        </button>
        <h2 className="heading">Filter &amp; Sort</h2>
        <p className="subheading">Select all that applies</p>
        <div className="filter-holder">
          <h2 className="title">Filter by</h2>
          <ul className="list">
            <li className="list-item">
              <label className="radio-item" htmlFor="check1">
                <input
                  id="check1"
                  className="checkbox"
                  type="radio"
                  name="filter"
                  value="new"
                  onChange={e => handleFilters(e.target.value)}
                  defaultChecked={sort === 'new' || undefined}
                />
                <span className="checkmark"></span>
                New Applications
              </label>
            </li>
            <li className="list-item">
              <label className="radio-item" htmlFor="check2">
                <input
                  id="check2"
                  className="checkbox"
                  type="radio"
                  name="filter"
                  value="week"
                  onChange={e => handleFilters(e.target.value)}
                  defaultChecked={sort === 'week' || undefined}
                />
                <span className="checkmark"></span>
                Applications this Week
              </label>
            </li>
            <li className="list-item">
              <label className="radio-item" htmlFor="check3">
                <input
                  id="check3"
                  className="checkbox"
                  type="radio"
                  name="filter"
                  value="month"
                  onChange={e => handleFilters(e.target.value)}
                  defaultChecked={sort === 'month' || undefined}
                />
                <span className="checkmark"></span>
                Applications this Month
              </label>
            </li>
          </ul>
        </div>
        <div className="sort-holder">
          <h2 className="title">Sort by</h2>
          <ul className="list">
            <li className="list-item">
              <label className="radio-item" htmlFor="radio1">
                <input
                  id="radio1"
                  className="radio"
                  type="radio"
                  name="sort"
                  value="recent"
                  onChange={e => handleFilters(e.target.value)}
                  defaultChecked={sort === 'recent' || sort === undefined}
                />
                <span className="checkmark"></span>
                Date Added
              </label>
            </li>
            <li className="list-item">
              <label className="radio-item" htmlFor="radio2">
                <input
                  id="radio2"
                  className="radio"
                  type="radio"
                  name="sort"
                  value="alphabetical"
                  onChange={e => handleFilters(e.target.value)}
                  defaultChecked={sort === 'alphabetical' || undefined}
                />
                <span className="checkmark"></span>
                Alphabetical
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Filter
