import React from 'react'
import { getAuth } from 'firebase/auth'
import Images from '../assets/images'
import Icons from '../assets/icons'
import { useAppContext } from '../context'
import classnames from 'classnames'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'
import Footer from './admin/Footer'

const SidePanel = () => {
  const { user } = useAppContext()
  const location = useLocation()
  const navigate = useNavigate()

  const signOut = async () => {
    const auth = getAuth()
    await auth.signOut()
  }

  const menuItems = [
    {
      icon: <Icons.UserCheck className="menu-item-icon" />,
      label: 'Verify Users',
      description: 'Approve or disapprove applications',
      path: '/admin/dashboard',
      onClick: () => {
        navigate('/admin/dashboard')
      },
    },
    {
      icon: <Icons.Commerce className="menu-item-icon" />,
      label: 'All Transactions',
      description: 'Manage and view transactions',
      path: '/admin/transactions',
      onClick: () => {
        navigate('/admin/transactions')
      },
    },
    {
      icon: <Icons.Payout className="menu-item-icon" />,
      label: 'All Payouts',
      description: 'Manage and view payouts',
      path: '/admin/payouts',
      onClick: () => {
        location.pathname !== '/admin/payouts' && navigate('/admin/payouts')
      },
      children: [
        {
          label: 'Upcoming Payouts',
          path: '/admin/payouts',
          onclick: () => {
            location.pathname !== '/admin/payouts' && navigate('/admin/payouts')
          },
        },
        {
          label: 'Payout History',
          path: '/admin/payout-history',
          onClick: () => {
            location.pathname !== '/admin/payout-history' &&
              navigate('/admin/payout-history')
          },
        },
      ],
    },
    {
      icon: <Icons.UserCheck className="menu-item-icon" />,
      label: 'Send Notification',
      description: 'Send a notification to a location',
      path: '/admin/notifications',
      onClick: () => {
        navigate('/admin/notifications')
      },
    },
    {
      icon: <Icons.UserCheck className="menu-item-icon" />,
      label: 'Register Rider',
      description: 'Register a rider',
      path: '/admin/register-rider',
      onClick: () => {
        navigate('/admin/register-rider')
      },
    },
    {
      icon: <Icons.Logout className="menu-item-icon" />,
      label: 'Logout',
      onClick: signOut,
    },
  ]

  return (
    <div className="sidepanel">
      <div className="logo-wrapper">
        <Images.LogoTagline />
      </div>
      <div className="user-profile">
        <Images.UserProfile className="user-avatar" />
        <p className="text">
          Hello, <span className="display-name">{user?.displayName}</span>
        </p>
      </div>
      <div className="menu-items">
        {menuItems.map(item => {
          return (
            <div
              key={item.label}
              className={classnames('menu-item', {
                active:
                  location.pathname === item.path ||
                  item.children?.some(child =>
                    matchPath(
                      {
                        path: child.path,
                        caseSensitive: true,
                        end: true,
                      },
                      location.pathname
                    )
                  ),
                expanded:
                  item.children?.length &&
                  (location.pathname === item.path ||
                    item.children?.some(child =>
                      matchPath(
                        {
                          path: child.path,
                          caseSensitive: true,
                          end: true,
                        },
                        location.pathname
                      )
                    )),
              })}
              onClick={item.onClick}>
              <div className="menu-item-content-wrapper">
                {item.icon}
                <div className="menu-item-content">
                  <span className="menu-item-label">{item.label}</span>
                  <span className="menu-item-description">
                    {item.description}
                  </span>
                  <div className="menu-item-arrow" />
                </div>
              </div>
              {item.children?.length &&
                (location.pathname === item.path ||
                  item.children?.some(child =>
                    matchPath(
                      {
                        path: child.path,
                        caseSensitive: true,
                        end: true,
                      },
                      location.pathname
                    )
                  )) && (
                  <div className="child-items">
                    {item.children.map(child => (
                      <div
                        className={classnames('child-item', {
                          active: matchPath(
                            {
                              path: child.path,
                              caseSensitive: true,
                              end: true,
                            },
                            location.pathname
                          ),
                        })}
                        key={child.label}
                        onClick={child.onClick}>
                        <span className="child-item-label">{child.label}</span>
                        <div className="child-item-arrow" />
                      </div>
                    ))}
                  </div>
                )}
            </div>
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

export default SidePanel
