export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'PHP',
})

export const getOrderMethodLabel = order_method => {
  const methodLabels = {
    pickup: 'Pick-up',
    meetup: 'Meet-up',
    instore: 'In-Store',
    delivery: 'Delivery',
    appointment: 'Appointment',
    walkin: 'Walk-in',
  }

  return methodLabels[order_method]
}
