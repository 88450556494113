import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <p className="copyright">© 2020 Servbees Pte Ltd</p>
      <p className="text">
        Made with{' '}
        <span role="img" aria-label="emoji">
          ❤️
        </span>{' '}
        in SG & PH
      </p>
      <p className="text">Admin Dashboard v.1</p>
    </footer>
  )
}

export default Footer
