import React, { useContext, useEffect } from 'react'

import { Context } from '../../context'
import baseAPI from '../../services/Api'

import Sidepanel from '../../layouts/Sidepanel'
import ContentPanel from '../../layouts/ContentPanel'
import ProfileInfo from '../../layouts/ProfileInfo'

const Dashboard = () => {
  const {
    user,
    setPendingRequest,
    activePendingRequest,
    setActivePendingRequest,
    setPendingRequestsLoader,
    setActivePendingRequestLoader,
  } = useContext(Context)

  useEffect(() => {
    let timeout
    if (user) {
      timeout = setTimeout(async () => {
        try {
          let response = await baseAPI.getVerificationRequests()

          if (!response.requests?.length) {
            setPendingRequestsLoader(false)
            setActivePendingRequestLoader(false)
            return
          }

          const userResponse = await baseAPI.getUser({
            uid: response.requests[0].uid,
          })

          response.requests.map((request, index) => {
            if (!index && userResponse?.data) {
              response.requests[index] = {
                ...userResponse.data,
                address: userResponse.data.addresses.find(
                  address => address.default
                ),
                verification: request,
              }
            } else {
              response.requests[index] = {
                verification: request,
              }
            }

            return request
          })

          setPendingRequest(response.requests)
          if (userResponse?.data) {
            setActivePendingRequest({
              ...userResponse.data,
              address: userResponse.data.addresses.find(
                address => address.default
              ),
              ...response.requests[0],
            })
          }

          setPendingRequestsLoader(false)
          setActivePendingRequestLoader(false)
        } catch (error) {
          console.error(error)
          alert(error.message)
        }
      }, 200)
    }

    return () => clearTimeout(timeout)
  }, [
    setActivePendingRequest,
    setActivePendingRequestLoader,
    setPendingRequest,
    setPendingRequestsLoader,
    user,
  ])

  return (
    <>
      <div className="dashboard">
        <div className="panel-wrapper">
          <Sidepanel />
          <ContentPanel />
        </div>
        {!!activePendingRequest && <ProfileInfo />}
      </div>
    </>
  )
}

export default Dashboard
