import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { Context } from '../context'

const PageNotFound = () => {
  const { token } = useContext(Context)
  return token ? (
    <Navigate to={'/admin/dashboard'} />
  ) : (
    <Navigate to={'/login'} />
  )
}

export default PageNotFound
