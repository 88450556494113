import { format } from 'date-fns'
import React, { useContext, useState } from 'react'

import Button from '../components/Button'
import Spinner from '../components/Spinner'
import { Context } from '../context'
import baseAPI from '../services/Api'
import Modal from './Modal'

import DefaultImage from '../assets/images/default-img.svg'
import EyeIcon from '../assets/images/eye-gray-icon.svg'
import FillupIcon from '../assets/images/fillup-icon.svg'
import IdIcon from '../assets/images/id-icon.svg'
import PhoneIcon from '../assets/images/phone-icon.svg'
import VerifiedIcon from '../assets/images/verified-blue-icon.svg'
import VerifiedIllustration from '../assets/images/verified-illustration.svg'

const ProfileInfo = () => {
  const {
    activePendingRequestLoader,
    activePendingRequest,
    setActivePendingRequest,
    pendingRequests,
    setPendingRequest,
  } = useContext(Context)
  const [viewSelfie, setviewSelfie] = useState(false)
  const [viewId, setviewId] = useState(false)
  const [confrimApprove, setConfirmApprove] = useState(false)
  const [approveRequest, setapproveRequest] = useState(false)
  const [confirmDisapprove, setConfirmDisapprove] = useState(false)
  const [reasons, setReasons] = useState([])
  const [rejectMessage, setRejectMessage] = useState('')
  const [approvedUserInfo, setApprovedUserInfo] = useState({})

  const handleApproveRequest = async () => {
    try {
      setApprovedUserInfo(activePendingRequest)

      const response = await baseAPI.updateVerificationRequest({
        body: { uid: activePendingRequest.uid, status: true },
      })

      if (!response.success) throw new Error(response.message)
      const index = pendingRequests.findIndex(
        request => activePendingRequest.uid === request.uid
      )

      setPendingRequest(pendingRequests => {
        pendingRequests.splice(index, 1)
        return pendingRequests
      })
      setActivePendingRequest(null)

      setapproveRequest(!approveRequest)
      setConfirmApprove(false)
    } catch (error) {
      console.error(error)
    }
  }

  const handleReasons = params => {
    const currentReasons = [...reasons]
    const index = reasons.indexOf(params)

    if (index === -1) {
      currentReasons.push(params)
    } else {
      currentReasons.splice(index, 1)
    }

    setReasons(currentReasons)
  }

  const handleDisapproveRequest = async () => {
    try {
      const response = await baseAPI.updateVerificationRequest({
        body: {
          uid: activePendingRequest.uid,
          status: false,
          reject_reasons: reasons,
          reject_message: rejectMessage,
        },
      })

      if (!response.success) throw new Error(response.message)
      const index = pendingRequests.findIndex(
        request => activePendingRequest.uid === request.uid
      )

      setPendingRequest(pendingRequests => {
        pendingRequests.splice(index, 1)
        return pendingRequests
      })
      setActivePendingRequest(null)

      setConfirmDisapprove(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="content">
      <div className="profile-info">
        {activePendingRequestLoader && (
          <div className="spinner-wrapper">
            <Spinner type="Oval" color="#ffd400" height={100} width={100} />
          </div>
        )}
        <div className="user-wrapper">
          <div className="user-img-holder">
            <img
              className="img"
              src={
                activePendingRequest?.profile_photo
                  ? activePendingRequest?.profile_photo
                  : DefaultImage
              }
              alt=""
            />
          </div>
          <div className="text-holder">
            <h2 className="name">{activePendingRequest.full_name}</h2>
            <p className="date">
              Date submitted:{' '}
              {activePendingRequest?.verification?.date_submitted?._seconds &&
                format(
                  new Date(
                    activePendingRequest.verification.date_submitted._seconds *
                      1000
                  ),
                  'PPPppp'
                )}
            </p>
          </div>
        </div>
        <div className="info-wrapper">
          <div className="heading-holder">
            <img className="icon" src={FillupIcon} alt="" />
            <h2 className="title">Profile Information</h2>
            <p className="subtitle">#{activePendingRequest.uid}</p>
          </div>
          <ul className="info-list">
            <li className="info-item">
              <p className="info-title">FullName</p>
              <p className="info-data">{activePendingRequest.full_name}</p>
            </li>
            <li className="info-item">
              <p className="info-title">Username</p>
              <p className="info-data">@{activePendingRequest.username}</p>
            </li>
            <li className="info-item">
              <p className="info-title">Email Address</p>
              <p className="info-data">{activePendingRequest?.email}</p>
            </li>
            <li className="info-item">
              <p className="info-title">Address</p>
              <p className="info-data">
                {activePendingRequest?.address?.full_address}
              </p>
            </li>
            <li className="info-item">
              <p className="info-title">Uploaded Selfie</p>
              <button
                href="/"
                className="btn btn-view"
                onClick={() => setviewSelfie(true)}>
                <img className="icon" src={EyeIcon} alt="" /> View File
              </button>
            </li>
          </ul>
        </div>
        <div className="info-wrapper">
          <div className="heading-holder">
            <img className="icon" src={PhoneIcon} alt="" />
            <h2 className="title">Verified Mobile Number</h2>
          </div>
          <ul className="info-list">
            <li className="info-item">
              <p className="info-title">Mobile Number</p>
              <p className="info-data">{activePendingRequest?.phone_number}</p>
            </li>
          </ul>
        </div>
        <div className="info-wrapper">
          <div className="heading-holder">
            <img className="icon" src={IdIcon} alt="" />
            <h2 className="title">Upload ID</h2>
          </div>
          <ul className="info-list">
            <li className="info-item">
              <p className="info-title">
                {activePendingRequest?.verification?.id?.type}
              </p>
              <button className="btn btn-view" onClick={() => setviewId(true)}>
                <img className="icon" src={EyeIcon} alt="" /> View File
              </button>
            </li>
          </ul>
        </div>
        <div className="button-holder">
          <button
            className="btn btn-outline"
            onClick={() => setConfirmDisapprove(true)}>
            Disapprove
          </button>
          <button
            className="btn btn-yellow"
            onClick={() => setConfirmApprove(true)}>
            Approve User
          </button>
        </div>
      </div>
      {viewSelfie ? (
        <Modal setViewModal={setviewSelfie} modalClass="selfie-modal">
          <h2 className="title">Uploaded Selfie</h2>
          <div className="image-holder">
            <img
              className="img"
              src={activePendingRequest.verification.selfie.image_url}
              alt=""
            />
          </div>
          <p className="text">
            Date submitted:
            {format(
              new Date(
                activePendingRequest.verification.date_submitted._seconds * 1000
              ),
              'PPPppp'
            )}
          </p>
          <p className="text">1MB</p>
        </Modal>
      ) : (
        <></>
      )}
      {viewId ? (
        <Modal setViewModal={setviewId} modalClass="id-modal">
          <h2 className="title">Uploaded Government ID</h2>
          <p className="subtitle">
            Type: {activePendingRequest.verification.id.type}
          </p>
          <div className="image-holder">
            <img
              className="img"
              src={activePendingRequest.verification.id.image_url}
              alt=""
            />
          </div>
          <p className="text">
            Date submitted:
            {format(
              new Date(
                activePendingRequest.verification.date_submitted._seconds * 1000
              ),
              'PPPppp'
            )}
          </p>
          <p className="text">1MB</p>
        </Modal>
      ) : (
        <></>
      )}
      {confrimApprove ? (
        <Modal setViewModal={setConfirmApprove} modalClass="approve-modal">
          <h2 className="title">Approve User</h2>
          <p className="subtitle">
            You are about to approve {activePendingRequest.full_name}’s request
            for verification
          </p>
          <div className="image-holder">
            <img className="img" src={VerifiedIllustration} alt="" />
          </div>
          <div className="button-holder">
            <Button className="btn-yellow" onClick={handleApproveRequest}>
              Approve Request
            </Button>
            <Button
              className="back-btn"
              onClick={() => setConfirmApprove(false)}>
              Go Back
            </Button>
          </div>
        </Modal>
      ) : (
        <> </>
      )}
      {approveRequest ? (
        <Modal
          setViewModal={setapproveRequest}
          modalClass={'approve-request-modal'}>
          <h2 className="title">User is verified</h2>
          <p className="subtitle">
            {approvedUserInfo?.full_name.split(' ')[0]} is now a verified
            Servbees user. A notification has been sent to{' '}
            {approvedUserInfo?.email}
          </p>
          <div className="user-holder">
            <div className="image-holder">
              <img
                className="img"
                src={
                  approvedUserInfo?.profile_photo
                    ? approvedUserInfo?.profile_photo
                    : DefaultImage
                }
                alt=""
              />
            </div>
            <p className="name">
              {approvedUserInfo?.full_name}
              <span className="verified-icon">
                <img className="icon" src={VerifiedIcon} alt="" />
              </span>
            </p>
            <p className="username">@{approvedUserInfo.username}</p>
          </div>
          <ul className="verified-list">
            <li className="verified-item">Government I.D.</li>
            <li className="verified-item">Mobile Number</li>
            <li className="verified-item">Email Address</li>
            <li className="verified-item">Home Address</li>
          </ul>
        </Modal>
      ) : (
        <> </>
      )}
      {confirmDisapprove ? (
        <Modal
          setViewModal={setConfirmDisapprove}
          modalClass={'disapprove-modal'}>
          <h2 className="title">Disapprove Application</h2>
          <p className="subtitle">
            You are about to disapprove Alex’s request for verification
          </p>
          <p className="text">Please specify reason:</p>
          <div className="reason-holder">
            <label className="checkbox-item" htmlFor="check1">
              <input
                id="check1"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Selfie is pixelated and blurry"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Selfie is pixelated and blurry
            </label>
            <label className="checkbox-item" htmlFor="check2">
              <input
                id="check2"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Government ID is pixelated and blurry"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Government ID is pixelated and blurry
            </label>
            <label className="checkbox-item" htmlFor="check3">
              <input
                id="check3"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Selfie is obscene"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Selfie is obscene
            </label>
            <label className="checkbox-item" htmlFor="check4">
              <input
                id="check4"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Government ID is expired"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Government ID is expired
            </label>
            <label className="checkbox-item" htmlFor="check5">
              <input
                id="check5"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Selfie and Government ID does not match"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Selfie and Government ID does not match
            </label>
            <label className="checkbox-item" htmlFor="check6">
              <input
                id="check6"
                className="checkbox"
                type="checkbox"
                name="filter"
                value="Government ID and Full Name does not match"
                onChange={e => handleReasons(e.target.value)}
              />
              <span className="checkmark"></span>
              Government ID and Full Name does not match
            </label>
            <textarea
              className="message-box"
              name="message"
              placeholder="Your message (Optional)"
              onChange={e => setRejectMessage(e.target.value)}
            />
          </div>
          <div className="button-holder">
            <Button
              className="btn-yellow"
              onClick={handleDisapproveRequest}
              disabled={!reasons.length}>
              Disapprove Request
            </Button>
            <Button
              className="back-btn"
              onClick={() => setConfirmDisapprove(false)}>
              Go Back
            </Button>
          </div>
        </Modal>
      ) : (
        <> </>
      )}
    </div>
  )
}

export default ProfileInfo
