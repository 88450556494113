import React from 'react'

const Button = ({ type, className, onClick, disabled, children }) => {
  return (
    <button
      type={type}
      className={`btn ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
