import React, { useState, useEffect, useContext } from 'react'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'

import { validateEmail } from '../../utils'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Spinner from '../../components/Spinner'
import Footer from '../../layouts/admin/Footer'

import Logo from '../../assets/images/logo.svg'
import EyeIcon from '../../assets/images/eye-icon.svg'
import CloseEyeIcon from '../../assets/images/close-eye-icon.svg'
import ArrowIcon from '../../assets/images/arrow-icon.svg'
import { Context } from '../../context'
import { Navigate } from 'react-router-dom'
import { firebaseApp } from '../../App'

const Login = () => {
  const { token } = useContext(Context)

  const [spinner, setSpinner] = useState(false)
  const [denied, setDenied] = useState(false)
  const [message, setMessage] = useState()
  const [validCredentials, setValidCredentials] = useState(false)
  const [passwordToggled, setPasswordToggled] = useState(false)
  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
  })
  const { email, password } = credentials

  const handleCredentials = e => {
    const { name, value } = e.target

    setCredentials({
      ...credentials,
      [name]: value,
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSpinner(true)

    try {
      const app = await firebaseApp
      const auth = getAuth(app)

      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      if (error.code === 'auth/wrong-password') {
        setMessage('Invalid Email/Password')
      } else if (error.code === 'auth/user-not-found') {
        setMessage('User not found')
      } else if (
        error.code === 'auth/account-exists-with-different-credential'
      ) {
        setMessage('Account exists with different credential')
      } else {
        setMessage('You are not authorized to access this page')
      }
      setSpinner(false)
      setDenied(true)
      console.error(error)
    }
  }

  useEffect(() => {
    if (validateEmail(email) && password.length >= 6) {
      setValidCredentials(true)
    } else {
      setValidCredentials(false)
    }
  }, [credentials, email, password.length])

  return token ? (
    <Navigate to="/admin/dashboard" />
  ) : (
    <>
      <div className="admin-login">
        <div className={`white-box ${denied ? 'denied' : ''}`}>
          <div className="login-content-wrapper">
            <div className="back-holder">
              <Button className="back-btn" onClick={() => setDenied(false)}>
                <img src={ArrowIcon} alt="" />
              </Button>
            </div>
            <div className="white-box-header">
              <img src={Logo} alt="" />
              {denied ? (
                <h1 className="title">Access Denied</h1>
              ) : (
                <h1 className="title">Welcome to Servbees</h1>
              )}
            </div>
            <div className="white-box-body">
              {denied ? (
                <div className="access-denied">
                  <p className="text">{message}</p>
                </div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    onChange={handleCredentials}
                    inputPlaceholder="Email Address"
                  />
                  <Input
                    type={passwordToggled ? 'text' : 'password'}
                    name="password"
                    value={password}
                    onChange={handleCredentials}
                    inputPlaceholder="Password">
                    <span
                      className="eye-icon"
                      onClick={() => setPasswordToggled(!passwordToggled)}>
                      <img
                        src={passwordToggled ? CloseEyeIcon : EyeIcon}
                        alt=""
                      />
                    </span>
                  </Input>
                  <div className="button-holder">
                    <Button
                      type="submit"
                      className={`btn-login ${
                        validCredentials ? 'btn-yellow' : 'btn-light'
                      }`}
                      disabled={!validCredentials || spinner}>
                      {spinner ? (
                        <Spinner
                          type="ThreeDots"
                          color="#fff"
                          height={22}
                          width={22}
                        />
                      ) : (
                        'Log in'
                      )}
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  )
}

export default Login
