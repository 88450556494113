import React from 'react'
import Textarea from 'react-expanding-textarea'

const TextareaInput = ({
  type,
  name,
  value,
  onChange,
  maxLength,
  inputPlaceholder,
  children,
}) => {
  return (
    <div className="input-holder">
      <Textarea
        type={type}
        name={name}
        rows={1}
        maxLength={maxLength || 100}
        value={value}
        onChange={onChange}
        className="textarea"
      />
      <span className="placeholder">{inputPlaceholder}</span>
      {children}
    </div>
  )
}

export default TextareaInput
