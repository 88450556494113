import React, { useCallback, useContext, useEffect, useState } from 'react'
import { formatDistanceToNow } from 'date-fns'
import { useDebouncedCallback } from 'use-debounce'
import baseAPI from '../services/Api'

import { Context } from '../context'
import Filter from './Filter'
import Spinner from '../components/Spinner'

import FilterIcon from '../assets/images/filter-icon.svg'
import SearchIcon from '../assets/images/search-icon.svg'
import DefaultImage from '../assets/images/default-img.svg'

const ContentPanel = () => {
  const {
    activePendingRequest,
    pendingRequestsLoader,
    setPendingRequestsLoader,
    setActivePendingRequestLoader,
    pendingRequests,
    setPendingRequest,
    setActivePendingRequest,
  } = useContext(Context)
  const [openFilter, setopenFilter] = useState(false)

  const handleSearch = useDebouncedCallback(async value => {
    setPendingRequestsLoader(true)
    const response = await baseAPI.getVerificationRequests({
      search: value,
    })

    const newPendingRequests = []
    response.requests.map(request => {
      const cachedRequest = pendingRequests.find(
        pendingRequest => pendingRequest.uid === request.uid
      )
      if (cachedRequest) {
        newPendingRequests.push(cachedRequest)
      } else {
        newPendingRequests.push({
          verification: request,
        })
      }

      return request
    })

    setPendingRequest(newPendingRequests)
    if (newPendingRequests.length) {
      if (newPendingRequests[0].uid) {
        setActivePendingRequest(newPendingRequests[0])
      } else {
        const userResponse = await baseAPI.getUser({
          uid: newPendingRequests[0].verification.uid,
        })
        setActivePendingRequest({
          ...userResponse.data,
          address: userResponse.data.addresses.find(address => address.default),
          ...newPendingRequests[0],
        })
      }
    }

    setPendingRequestsLoader(false)
  }, 1000)

  const handleActivePendingRequest = useCallback(
    async (id, index) => {
      try {
        if (!id) return
        setActivePendingRequestLoader(true)

        setActivePendingRequest(pendingRequests[index])
        if (!pendingRequests[index].uid) {
          const user = await baseAPI.getUser({ uid: id })

          setActivePendingRequest({
            ...user.data,
            address: user.data.addresses.find(address => address.default),
            ...pendingRequests[index],
          })

          let currentPendingRequest = pendingRequests
          currentPendingRequest[index] = {
            ...currentPendingRequest[index],
            ...user.data,
            address: user.data.addresses.find(address => address.default),
          }
          setPendingRequest(currentPendingRequest)
        }

        setActivePendingRequestLoader(false)
      } catch (error) {
        console.error(error)
      }
    },
    [
      pendingRequests,
      setActivePendingRequest,
      setActivePendingRequestLoader,
      setPendingRequest,
    ]
  )

  useEffect(() => {
    if (!activePendingRequest) {
      handleActivePendingRequest(pendingRequests[0]?.verification?.uid, 0)
    }
  }, [activePendingRequest, pendingRequests, handleActivePendingRequest])

  return (
    <>
      <div className="content-panel">
        <h2 className="title">Verify Users</h2>
        <p className="desc">something, something</p>
        <div className="search-holder">
          <div className="search-bar">
            <input
              className="search"
              type="search"
              placeholder="Search..."
              onChange={e => handleSearch.callback(e.target.value)}
            />
            <img className="search-icon" src={SearchIcon} alt="" />
          </div>
          <div className="button-holder">
            <button
              className="btn btn-filter"
              onClick={() => setopenFilter(true)}>
              <img src={FilterIcon} alt="" />
            </button>
          </div>
        </div>
        <div className="total-sort">
          <p className="total-pending">
            Total Pending: {pendingRequests.length}
          </p>
          <p className="sort">Sorted by: Date added</p>
        </div>
        <div className="result-list">
          {pendingRequestsLoader && (
            <div className="spinner-wrapper">
              <Spinner type="Oval" color="#ffd400" height={100} width={100} />
            </div>
          )}
          {pendingRequests.map((item, i) => {
            return !item.verification ? null : (
              <button
                key={i}
                className={`result-item ${
                  activePendingRequest?.uid === item.verification.uid
                    ? 'selected-user'
                    : ''
                }`}
                onClick={() =>
                  handleActivePendingRequest(item.verification.uid, i)
                }
                data-id={item.verification.uid}
                data-count={i}>
                <div className="user-profile">
                  <img
                    className="profile-img"
                    src={
                      item.verification.profile_photo
                        ? item.verification.profile_photo
                        : DefaultImage
                    }
                    alt=""
                  />
                </div>
                <div className="info-holder">
                  <p className="name">{item.verification.full_name}</p>
                  <p className="username">@{item.verification.username}</p>
                </div>
                <span className="time">
                  {formatDistanceToNow(
                    new Date(item.verification.date_submitted._seconds * 1000)
                  )}
                </span>
              </button>
            )
          })}
        </div>
      </div>
      {openFilter ? <Filter setopenFilter={setopenFilter} /> : <> </>}
    </>
  )
}

export default ContentPanel
