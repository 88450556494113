import { checkToken } from './auth.service'
import { getAuth } from 'firebase/auth'
/* eslint-disable */
/*jshint esversion: 6 */
/*global fetch, btoa */
import Q from 'q'
/**
 * Servbees API
 * @class API
 * @param {(string|object)} [domainOrOptions] - The project domain or options object. If object, see the object's optional properties.
 * @param {string} [domainOrOptions.domain] - The project domain
 * @param {object} [domainOrOptions.token] - auth token - object with value property and optional headerOrQueryName and isQuery properties
 */
let API = (function () {
  'use strict'

  function API(options) {
    let domain = typeof options === 'object' ? options.domain : options
    this.domain = domain ? domain : 'https://api.servbees.com'
    if (this.domain.length === 0) {
      throw new Error('Domain parameter must be specified as a string.')
    }
    this.apiKey =
      typeof options === 'object' ? (options.apiKey ? options.apiKey : {}) : {}
  }

  function serializeQueryParams(parameters) {
    let str = []
    for (let p in parameters) {
      if (parameters.hasOwnProperty(p)) {
        str.push(
          encodeURIComponent(p) + '=' + encodeURIComponent(parameters[p])
        )
      }
    }
    return str.join('&')
  }

  function mergeQueryParams(parameters, queryParameters) {
    if (parameters.$queryParameters) {
      Object.keys(parameters.$queryParameters).forEach(function (
        parameterName
      ) {
        let parameter = parameters.$queryParameters[parameterName]
        queryParameters[parameterName] = parameter
      })
    }
    return queryParameters
  }

  /**
   * HTTP Request
   * @method
   * @name API#request
   * @param {string} method - http method
   * @param {string} url - url to do request
   * @param {object} parameters
   * @param {object} body - body parameters / object
   * @param {object} headers - header parameters
   * @param {object} queryParameters - querystring parameters
   * @param {object} form - form data object
   * @param {object} deferred - promise object
   */
  API.prototype.request = async function (
    method,
    url,
    parameters,
    body,
    headers,
    queryParameters,
    form,
    deferred
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? serializeQueryParams(queryParameters)
        : null
    const urlWithParams = url + (queryParams ? '?' + queryParams : '')

    if (body && !Object.keys(body).length) {
      body = undefined
    }

    if (!this.tokenRefresher) {
      this.tokenRefresher = checkToken().then(() => {
        this.tokenRefresher = null
      })
    }
    await this.tokenRefresher
    const token = sessionStorage.getItem('token')
    headers.Authorization = `Bearer ${token}`
    fetch(urlWithParams, {
      method,
      headers,
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.ok) {
          return response.json()
        } else if (response.status === 401) {
          const auth = getAuth()
          auth.signOut()
          throw new Error('Unauthorized')
        } else {
          throw new Error('Oops! Something went wrong.')
        }
      })
      .then(body => {
        deferred.resolve(body)
      })
      .catch(error => {
        deferred.reject(error)
      })
  }

  /**
   * Set Api Key
   * @method
   * @name API#setApiKey
   * @param {string} value - apiKey's value
   * @param {string} headerOrQueryName - the header or query name to send the apiKey at
   * @param {boolean} isQuery - true if send the apiKey as query param, otherwise, send as header param
   */
  API.prototype.setApiKey = function (value, headerOrQueryName, isQuery) {
    this.apiKey.value = value
    this.apiKey.headerOrQueryName = headerOrQueryName
    this.apiKey.isQuery = isQuery
  }
  /**
   * Set Auth headers
   * @method
   * @name API#setAuthHeaders
   * @param {object} headerParams - headers object
   */
  API.prototype.setAuthHeaders = function (headerParams) {
    let headers = headerParams ? headerParams : {}
    if (!this.apiKey.isQuery && this.apiKey.headerOrQueryName) {
      headers[this.apiKey.headerOrQueryName] = this.apiKey.value
    }
    return headers
  }

  /**
   * Get activities data
   * @method
   * @name API#getActivities
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {string} parameters.category - activity category
   * @param {array} parameters.type - post type filter
   */
  API.prototype.getActivities = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/activities'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['category'] !== undefined) {
      queryParameters['category'] = parameters['category']
    }

    if (parameters['category'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: category'))
      return deferred.promise
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get post activities
   * @method
   * @name API#getPostActivities
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {string} parameters.id - post id
   * @param {string} parameters.status - activity status
   */
  API.prototype.getPostActivities = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/activities/post-activities'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id']
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status']
    }

    if (parameters['status'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: status'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Send notification to users in location
   * @method
   * @name API#sendNotificationToUsersInLocation
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Location and notification data
   */
  API.prototype.sendNotificationToUsersInLocation = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/admin/send-notification-to-users-in-location'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Contact us
   * @method
   * @name API#contactUs
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Message data
   */
  API.prototype.contactUs = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/contact-us'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get active deliveries
   * @method
   * @name API#getActiveDeliveries
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getActiveDeliveries = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/deliveries/{uid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update delivery
   * @method
   * @name API#updateDelivery
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - delivery id
   * @param {} parameters.body - Delivery data
   */
  API.prototype.updateDelivery = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/deliveries/{id}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PATCH',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * get app version
   * @method
   * @name API#getAppVersion
   * @param {object} parameters - method options and parameters
   */
  API.prototype.getAppVersion = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/app-version'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * inspect banned words
   * @method
   * @name API#inspectBannedWords
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Banned words body
   */
  API.prototype.inspectBannedWords = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/misc/inspect-banned-words'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Rider Sign up
   * @method
   * @name API#riderSignUp
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - User data
   */
  API.prototype.riderSignUp = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/rider-sign-up'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Sign up
   * @method
   * @name API#signUp
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - User data
   */
  API.prototype.signUp = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/sign-up'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Save location
   * @method
   * @name API#saveLocation
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - Location
   */
  API.prototype.saveLocation = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/location'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Login
   * @method
   * @name API#login
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Credentials
   */
  API.prototype.login = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/login'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Save signup medata
   * @method
   * @name API#saveSignUpMetadata
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Servbees API
   */
  API.prototype.saveSignUpMetadata = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/signup-metadata'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Forgot password
   * @method
   * @name API#forgotPassword
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Data
   */
  API.prototype.forgotPassword = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/forgot-password'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Reset password
   * @method
   * @name API#resetPassword
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Location
   */
  API.prototype.resetPassword = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/reset-password'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Quote delivery
   * @method
   * @name API#quoteDelivery
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.quoteDelivery = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/orders/beebo-rider/quote/{uid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Create order
   * @method
   * @name API#createOrder
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Order data
   */
  API.prototype.createOrder = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/orders'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update order
   * @method
   * @name API#updateOrder
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - order id
   * @param {} parameters.body - Order data
   */
  API.prototype.updateOrder = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/orders/{id}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PATCH',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Notify customer
   * @method
   * @name API#notifyCustomer
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - order id
   * @param {} parameters.body - Order data
   */
  API.prototype.notifyCustomer = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/orders/{id}/notifyCustomer'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PATCH',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Cancel Delivery Request
   * @method
   * @name API#cancelDeliveryRequest
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - order id
   */
  API.prototype.cancelDeliveryRequest = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/orders/{id}/cancelDeliveryRequest'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get OTP data
   * @method
   * @name API#getOtpData
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - Otp id
   */
  API.prototype.getOtpData = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/otp/{id}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Verify OTP
   * @method
   * @name API#verifyOtp
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Otp code
   */
  API.prototype.verifyOtp = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/otp/verify'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Resend otp
   * @method
   * @name API#resendOtp
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Otp code
   */
  API.prototype.resendOtp = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/otp/resend'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Create Card Payment
   * @method
   * @name API#createCardPayment
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Card Payment Data
   */
  API.prototype.createCardPayment = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payments/card'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Create e-wallet payment source
   * @method
   * @name API#createPaymentSource
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Source Data
   */
  API.prototype.createPaymentSource = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payments/e-wallet/source'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Create Paypal payment
   * @method
   * @name API#createPaypalPayment
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Paypal Data
   */
  API.prototype.createPaypalPayment = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payments/paypal'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Execute Paypal payment
   * @method
   * @name API#executePaypalPayment
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Paypal payment data
   */
  API.prototype.executePaypalPayment = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payments/paypal/execute'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Save payout method
   * @method
   * @name API#savePayoutMethod
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Payout Method Data
   */
  API.prototype.savePayoutMethod = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payout-methods'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get payouts
   * @method
   * @name API#getPayouts
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {string} parameters.sort - sort by
   * @param {string} parameters.method - payout method
   * @param {string} parameters.status - payout status
   */
  API.prototype.getPayouts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['sort'] !== undefined) {
      queryParameters['sort'] = parameters['sort']
    }

    if (parameters['sort'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: sort'))
      return deferred.promise
    }

    if (parameters['method'] !== undefined) {
      queryParameters['method'] = parameters['method']
    }

    if (parameters['status'] !== undefined) {
      queryParameters['status'] = parameters['status']
    }

    if (parameters['status'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: status'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get payout stats
   * @method
   * @name API#getPayoutStats
   * @param {object} parameters - method options and parameters
   */
  API.prototype.getPayoutStats = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts/stats'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get payout details
   * @method
   * @name API#getPayout
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - document id
   */
  API.prototype.getPayout = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts/{id}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * release payout
   * @method
   * @name API#releasePayout
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - payout id
   * @param {} parameters.body - payout status
   */
  API.prototype.releasePayout = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts/{id}/release'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get payout orders
   * @method
   * @name API#getPayoutOrders
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - document id
   */
  API.prototype.getPayoutOrders = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts/{id}/orders'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{id}', parameters['id'])

    if (parameters['id'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: id'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Export payouts
   * @method
   * @name API#exportPayouts
   * @param {object} parameters - method options and parameters
   */
  API.prototype.exportPayouts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/payouts/export/csv'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['text/csv']

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get posts
   * @method
   * @name API#getPosts
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {integer} parameters.radius - radius in meters
   * @param {number} parameters.lat - latitude
   * @param {number} parameters.lon - longitude
   * @param {string} parameters.city - city
   * @param {array} parameters.type - post type filter
   * @param {string} parameters.sort - sort by
   * @param {array} parameters.availability - post availability
   * @param {string} parameters.uid - owner id
   */
  API.prototype.getPosts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['radius'] !== undefined) {
      queryParameters['radius'] = parameters['radius']
    }

    if (parameters['lat'] !== undefined) {
      queryParameters['lat'] = parameters['lat']
    }

    if (parameters['lon'] !== undefined) {
      queryParameters['lon'] = parameters['lon']
    }

    if (parameters['city'] !== undefined) {
      queryParameters['city'] = parameters['city']
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type']
    }

    if (parameters['sort'] !== undefined) {
      queryParameters['sort'] = parameters['sort']
    }

    if (parameters['sort'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: sort'))
      return deferred.promise
    }

    if (parameters['availability'] !== undefined) {
      queryParameters['availability'] = parameters['availability']
    }

    if (parameters['uid'] !== undefined) {
      queryParameters['uid'] = parameters['uid']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Create post
   * @method
   * @name API#createPost
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Post data
   */
  API.prototype.createPost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get post
   * @method
   * @name API#getPost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.getPost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Delete post
   * @method
   * @name API#deletePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.deletePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'DELETE',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update post
   * @method
   * @name API#updatePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   * @param {} parameters.body - Post data
   */
  API.prototype.updatePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PUT',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Hide post
   * @method
   * @name API#hidePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.hidePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/hide'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Unhide post
   * @method
   * @name API#unhidePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.unhidePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/unhide'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Report post
   * @method
   * @name API#reportPost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   * @param {} parameters.body - Data
   */
  API.prototype.reportPost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/report'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['applicaction/json, application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Archive post
   * @method
   * @name API#archivePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.archivePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/archive'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Set availability
   * @method
   * @name API#setAvailability
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   * @param {} parameters.body - Availability
   */
  API.prototype.setAvailability = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/availability'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Unarchive post
   * @method
   * @name API#unarchivePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.unarchivePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/unarchive'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Like post
   * @method
   * @name API#likePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.likePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/like'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Unlike post
   * @method
   * @name API#unlikePost
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.pid - post id
   */
  API.prototype.unlikePost = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/posts/{pid}/unlike'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{pid}', parameters['pid'])

    if (parameters['pid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: pid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get transactions
   * @method
   * @name API#getTransactions
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {string} parameters.sort - sort by
   */
  API.prototype.getTransactions = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/transactions'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['sort'] !== undefined) {
      queryParameters['sort'] = parameters['sort']
    }

    if (parameters['sort'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: sort'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Export transactions
   * @method
   * @name API#exportTransactions
   * @param {object} parameters - method options and parameters
   */
  API.prototype.exportTransactions = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/transactions/export'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['text/csv']

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get transaction details
   * @method
   * @name API#getTransactionDetails
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.id - order id
   */
  API.prototype.getTransactionDetails = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/transactions/{id}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['id'] !== undefined) {
      queryParameters['id'] = parameters['id']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Delete Account
   * @method
   * @name API#deleteAccount
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - Account Deletion Data
   */
  API.prototype.deleteAccount = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/delete-account'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get users
   * @method
   * @name API#getUsers
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   */
  API.prototype.getUsers = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get active riders
   * @method
   * @name API#getActiveRiders
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   */
  API.prototype.getActiveRiders = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/active-riders'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user
   * @method
   * @name API#getUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update user
   * @method
   * @name API#updateUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - User data
   */
  API.prototype.updateUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PUT',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Change password
   * @method
   * @name API#changePassword
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - Data
   */
  API.prototype.changePassword = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/change-password'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PUT',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Validate username
   * @method
   * @name API#validateUsername
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Username
   */
  API.prototype.validateUsername = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/verify-username'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Block user
   * @method
   * @name API#blockUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.blockUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/block'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Unblock user
   * @method
   * @name API#unblockUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.unblockUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/unblock'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get list of blocked users
   * @method
   * @name API#getBlockedUsers
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {integer} parameters.limit - number of post per page
   * @param {string} parameters.lastId - last id
   */
  API.prototype.getBlockedUsers = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/blocks'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Report user
   * @method
   * @name API#reportUser
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - Data
   */
  API.prototype.reportUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/report'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update temperature
   * @method
   * @name API#updateTemperature
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - Data
   */
  API.prototype.updateTemperature = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/temperature'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get current temperature
   * @method
   * @name API#getCurrentTemperature
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getCurrentTemperature = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/current-temperature'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get temperature history
   * @method
   * @name API#getTemperatureHistory
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {integer} parameters.limit - number of post per page
   * @param {string} parameters.order - sort order
   * @param {string} parameters.string - sort by field
   * @param {string} parameters.lastId - last id
   */
  API.prototype.getTemperatureHistory = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/temperature-history'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['order'] !== undefined) {
      queryParameters['order'] = parameters['order']
    }

    if (parameters['string'] !== undefined) {
      queryParameters['string'] = parameters['string']
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Follow user
   * @method
   * @name API#followUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.followUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/follow'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Unfollow user
   * @method
   * @name API#unfollowUser
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.unfollowUser = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/unfollow'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Is following
   * @method
   * @name API#isFollowing
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.isFollowing = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/is-following'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get following
   * @method
   * @name API#getFollowing
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {integer} parameters.limit - number of post per page
   * @param {string} parameters.lastId - last id
   */
  API.prototype.getFollowing = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/following'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get followers
   * @method
   * @name API#getFollowers
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {integer} parameters.limit - number of post per page
   * @param {string} parameters.lastId - last id
   */
  API.prototype.getFollowers = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/followers'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Submit Government ID
   * @method
   * @name API#submitGovernmentId
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - data
   */
  API.prototype.submitGovernmentId = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/verification/government-id'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Submit Verification Request
   * @method
   * @name API#submitVerificationRequest
   * @param {object} parameters - method options and parameters
   */
  API.prototype.submitVerificationRequest = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/verification/submit'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get pending verify account
   * @method
   * @name API#getVerificationRequests
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {array} parameters.type - post type filter
   * @param {string} parameters.sort - sort by
   */
  API.prototype.getVerificationRequests = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/verification/requests'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['type'] !== undefined) {
      queryParameters['type'] = parameters['type']
    }

    if (parameters['sort'] !== undefined) {
      queryParameters['sort'] = parameters['sort']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update verify request
   * @method
   * @name API#updateVerificationRequest
   * @param {object} parameters - method options and parameters
   * @param {} parameters.body - data
   */
  API.prototype.updateVerificationRequest = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/verification/requests'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PUT',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get list of user's posts
   * @method
   * @name API#getUserPosts
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {array} parameters.availability - post availability
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUserPosts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/posts'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    if (parameters['availability'] !== undefined) {
      queryParameters['availability'] = parameters['availability']
    }

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get list of archived posts
   * @method
   * @name API#getArchivedPosts
   * @param {object} parameters - method options and parameters
   * @param {integer} parameters.limit - number of post per page
   * @param {integer} parameters.page - current page
   * @param {string} parameters.search - search query
   * @param {string} parameters.uid - user id
   */
  API.prototype.getArchivedPosts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/posts/archived'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['page'] !== undefined) {
      queryParameters['page'] = parameters['page']
    }

    if (parameters['search'] !== undefined) {
      queryParameters['search'] = parameters['search']
    }

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get list of liked posts
   * @method
   * @name API#getLikedPosts
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {string} parameters.lastId - Servbees API
   * @param {number} parameters.limit - Servbees API
   */
  API.prototype.getLikedPosts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/posts/liked'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get list of hidden posts
   * @method
   * @name API#getHiddenPosts
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {string} parameters.lastId - Servbees API
   * @param {number} parameters.limit - Servbees API
   */
  API.prototype.getHiddenPosts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/posts/hidden'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['lastId'] !== undefined) {
      queryParameters['last_id'] = parameters['lastId']
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user categories with count
   * @method
   * @name API#getUserCategories
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUserCategories = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/categories'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user's cart data
   * @method
   * @name API#getCart
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getCart = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/cart'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Update user's cart data
   * @method
   * @name API#updateCart
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - Cart data
   */
  API.prototype.updateCart = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/cart'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'PUT',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Change user's email or phone number
   * @method
   * @name API#changeEmailOrPhoneNumber
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {} parameters.body - data
   */
  API.prototype.changeEmailOrPhoneNumber = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/change-email-or-phone-number'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']
    headers['Content-Type'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['body'] !== undefined) {
      body = parameters['body']
    }

    if (parameters['body'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: body'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user by username
   * @method
   * @name API#getUserByUsername
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.username - username
   */
  API.prototype.getUserByUsername = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{username}/user'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{username}', parameters['username'])

    if (parameters['username'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: username'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user detail counts
   * @method
   * @name API#getUserCounts
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUserCounts = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/counts'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get username
   * @method
   * @name API#getUsername
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUsername = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/username'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user payout method
   * @method
   * @name API#getUserPayoutMethod
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   */
  API.prototype.getUserPayoutMethod = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/payout-method'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Mark all notifications as read
   * @method
   * @name API#markAllNotificationsAsRead
   * @param {object} parameters - method options and parameters
   */
  API.prototype.markAllNotificationsAsRead = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/notifications/mark-all-as-read'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers['Accept'] = ['application/json']

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'POST',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }
  /**
   * Get user transactions
   * @method
   * @name API#getUserTransactions
   * @param {object} parameters - method options and parameters
   * @param {string} parameters.uid - user id
   * @param {string} parameters.lastItemId - last item id
   * @param {integer} parameters.limit - number of post per page
   * @param {string} parameters.category - transaction category
   */
  API.prototype.getUserTransactions = function (parameters) {
    if (parameters === undefined) {
      parameters = {}
    }
    let deferred = Q.defer()
    let domain = this.domain,
      path = '/users/{uid}/transactions'
    let body = {},
      queryParameters = {},
      headers = {},
      form = {}

    headers = this.setAuthHeaders(headers)
    headers['Accept'] = ['application/json']

    path = path.replace('{uid}', parameters['uid'])

    if (parameters['uid'] === undefined) {
      deferred.reject(new Error('Missing required  parameter: uid'))
      return deferred.promise
    }

    if (parameters['lastItemId'] !== undefined) {
      queryParameters['last_item_id'] = parameters['lastItemId']
    }

    if (parameters['limit'] !== undefined) {
      queryParameters['limit'] = parameters['limit']
    }

    if (parameters['category'] !== undefined) {
      queryParameters['category'] = parameters['category']
    }

    queryParameters = mergeQueryParams(parameters, queryParameters)

    this.request(
      'GET',
      domain + path,
      parameters,
      body,
      headers,
      queryParameters,
      form,
      deferred
    )

    return deferred.promise
  }

  return API
})()

const Api = new API()
export default Api
