import React from 'react'

import CloseIcon from '../assets/images/close-light-icon.svg'

const Modal = ({ setViewModal, modalClass, children }) => {
  return (
    <div className={`modal ${modalClass}`}>
      <div className="modal-overlay" onClick={() => setViewModal(false)}></div>
      <div className="modal-wrapper">
        <button className="btn close-btn" onClick={() => setViewModal(false)}>
          <img className="img" src={CloseIcon} alt="" />
        </button>
        {children}
      </div>
    </div>
  )
}

export default Modal
