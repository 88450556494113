import React, { useContext } from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import { Context } from '../context'

const PrivateRoutes = () => {
  const { token, role } = useContext(Context)
  return token && role === 'admin' ? <Outlet /> : <Navigate to="/login" />
}

export default PrivateRoutes
