import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import DataTable from 'react-data-table-component'
import classNames from 'classnames'
import Modal from 'react-modal'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ReactReadMoreReadLess from 'react-read-more-read-less'

import Input from '../../components/Input'
import TextareaInput from '../../components/TextareaInput'
import Api from '../../services/Api'
import Icons from '../../assets/icons'
import Images from '../../assets/images'
import { useAppContext } from '../../context'
import { getValue } from 'firebase/remote-config'
import { useNavigate } from 'react-router-dom'

Modal.setAppElement('#root')

const refNumberMaxLimit = 9

const validationSchema = yup
  .object()
  .shape({
    refNumber: yup.string().max(refNumberMaxLimit, 'length').required(),
    otherNotes: yup.string().optional(),
  })
  .required()

const Payouts = () => {
  const { id } = useParams()
  const { remoteConfig } = useAppContext()

  const {
    control,
    handleSubmit,
    formState: { isValid },
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      refNumber: '',
      otherNotes: '',
    },
  })
  const watchNotes = watch('otherNotes', '')

  const [payoutData, setPayoutData] = useState({})
  const [payoutMethod, setPayoutMethod] = useState()
  const [payoutPosts, setPayoutPosts] = useState([])
  const [isReleaseModalOpen, setIsReleaseModalOpen] = useState(false)
  const [isReleasing, setIsReleasing] = useState(false)
  const navigate = useNavigate()

  const transferFee = useMemo(() => {
    if (!!remoteConfig) {
      const feesObject = getValue(remoteConfig, 'fees')
      if (!feesObject) {
        return {}
      }

      const fees = JSON.parse(feesObject.asString())
      return fees?.transfer_fee?.offset || 0
    } else {
      return 0
    }
  }, [remoteConfig])

  const postIcons = {
    need: <Icons.Need width={12.5} height={12.5} />,
    sell: <Icons.Sell />,
    service: <Icons.Service />,
  }

  const customStyles = React.useMemo(() => {
    return {
      table: {
        style: {
          padding: 0,
        },
      },
      headRow: {
        style: {
          backgroundColor: '#1F1A54',
          borderBottomWidth: '1px',
          borderBottomStyle: 'solid',
          borderRadius: '4px',
        },
      },
      headCells: {
        style: {
          color: '#FFFFFF !important',
          fontWeight: '500',
          '& .rdt_TableCol': {
            color: '#FFFFFF',
          },
          '& .rdt_TableCol_Sortable': {
            color: '#FFFFFF',
          },
          '& .rdt_TableCol:hover': {
            color: '#FFFFFF !important',
            opacity: '0.75',
          },
          '& .rdt_TableCol:focus': {
            color: '#FFFFFF !important',
            opacity: '0.75',
          },
          '& .rdt_TableCol_Sortable:hover': {
            color: '#FFFFFF !important',
            opacity: '0.75',
          },
          '& .rdt_TableCol_Sortable:focus': {
            color: '#FFFFFF !important',
            opacity: '0.75',
          },
        },
      },
      rows: {
        style: {
          minHeight: '80px',
          paddingTop: '16px',
          paddingBottom: '16px',
          '&:not(:last-of-type)': {
            borderBottomStyle: 'solid',
            borderBottomWidth: '1px',
          },
        },
      },
    }
  }, [])

  const ordersColumns = React.useMemo(
    () => [
      {
        name: 'Order ID',
        selector: 'id',
        sortable: true,
        width: '180px',
      },
      {
        name: 'Seller name',
        selector: 'seller_name',
        sortable: true,
      },
      {
        name: 'Buyer Name',
        selector: 'buyer_name',
        sortable: true,
      },
      {
        name: 'Post Type',
        selector: 'post_type',
        cell: row => {
          const icon = postIcons[row.post_type]
          return (
            <>
              <div className={classNames('post-icon-wrapper', row.post_type)}>
                {icon}
              </div>
              <span className="post-type-label">{row.post_type}</span>
            </>
          )
        },
      },
      {
        name: 'Order Total',
        selector: 'net_amount',
        cell: row =>
          `₱${(
            Math.trunc(parseFloat(row.net_amount.toFixed(2)) * 100) / 100
          ).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}`,
      },
      {
        name: 'Order Status',
        selector: 'order_status',
        cell: () => 'Completed',
      },
      {
        name: 'Method',
        selector: 'payment_method',
      },
      {
        name: 'Status',
        selector: 'status',
        cell: () => (
          <span
            className={classNames(
              'payout-post-order-status',
              payoutData.status || 'pending'
            )}>
            {payoutData.status || 'pending'}
          </span>
        ),
        width: '110px',
      },
      {
        name: 'Payment ID',
        selector: 'payment_id',
        width: '250px',
      },
    ],
    [payoutData, postIcons]
  )

  const init = useCallback(async () => {
    try {
      const response = await Api.getPayout({ id })
      if (!response.success) throw new Error(response.message)
      setPayoutData(response.data)

      const paymentMethodResponse = await Api.getUserPayoutMethod({
        uid: response.data.uid,
      })
      if (!paymentMethodResponse.success) throw new Error(response.message)
      setPayoutMethod(paymentMethodResponse.data)

      const promises = []
      promises.push(
        Promise.all(
          (response.data.posts || []).map(async pid => {
            const response = await Api.getPost({ pid })
            if (!response.success) throw new Error(response.message)
            return response.data
          })
        )
      )

      promises.push(
        (async () => {
          const response = await Api.getPayoutOrders({ id })
          return response.data
        })()
      )

      const [posts, orders] = await Promise.all(promises)
      const postObject = posts.reduce((posts, post) => {
        posts[post.id] = post
        return posts
      }, {})

      for (const order of orders) {
        if (postObject[order.post_id]) {
          postObject[order.post_id].orders =
            postObject[order.post_id].orders || []
          order.post_type = postObject[order.post_id].type
          postObject[order.post_id].orders.push(order)
        }
      }

      setPayoutPosts(Object.values(postObject))
    } catch (error) {
      console.error(error)
    }
  }, [id, setPayoutData])

  const handleOnBackClick = () => {
    navigate(-1)
  }

  const handleOnReleasePayoutClick = () => {
    setIsReleaseModalOpen(true)
  }

  const handleOnConfirmReleaseClick = async formData => {
    const { refNumber, otherNotes } = formData

    if (payoutData.status === 'paid') return
    try {
      setIsReleasing(true)
      const response = await Api.releasePayout({
        id,
        body: {
          refNumber: refNumber,
          otherNotes: otherNotes,
        },
      })
      if (!response.success) throw new Error(response.success)
      init()
      setIsReleaseModalOpen(false)
      setIsReleasing(false)
      setPayoutData(payoutData => ({
        ...payoutData,
        status: 'paid',
        ref_number: refNumber,
        other_notes: otherNotes,
      }))
      reset()
    } catch (error) {
      setIsReleasing(false)
      console.error(error)
    }
  }

  const modalStyles = {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  }

  useEffect(() => {
    init()
  }, [init])

  const payoutMethodLabels = {
    gcash: 'GCash',
    bank: 'Bank',
    paypal: 'PayPal',
  }

  return (
    <>
      <div className="payout-details">
        <div className="content-header">
          <button className="back-button-wrapper" onClick={handleOnBackClick}>
            <div className="back-button">
              <Icons.Back className="back-icon" />
            </div>
            <span className="back-button-label">Upcoming payout</span>
          </button>

          <Images.LogoTagline className="payout-details-logo" />
        </div>
        <div className="payout-details-content">
          <div className="payout-details-header">
            <h3 className="header-title">Payout Details</h3>
            <span className="header-subtitle">
              For User ID: {payoutData.uid}
            </span>
          </div>

          <div className="section user-details-section">
            <div className="user-details">
              <div className="flex align-center">
                <Icons.UploadId className="section-label-icon" />
                <h3>User Details</h3>
              </div>
              <div className="user-details-column">
                <span className="user-details-column-label">User ID</span>
                <span>{payoutData.uid}</span>
              </div>
              <div className="user-details-column">
                <span className="user-details-column-label">Post Title/s</span>
                <div className="user-details-column-value">
                  {payoutPosts.map(post => (
                    <span key={post.id}>{post.title}</span>
                  ))}
                </div>
              </div>
              <div className="user-details-column">
                <span className="user-details-column-label">
                  Seller / Service Provider
                </span>
                <span>
                  {payoutData.user
                    ? payoutData.user.full_name || payoutData.user.display_name
                    : 'N/A'}
                </span>
              </div>
              <div className="user-details-column">
                <span className="user-details-column-label">Payout Method</span>
                <span>
                  {payoutData.payout_method
                    ? payoutMethodLabels[payoutData.payout_method]
                    : 'N/A'}
                </span>
              </div>
              {!!payoutMethod?.bank && (
                <div className="user-details-column">
                  <span className="user-details-column-label">
                    Payout Method
                  </span>
                  <span>{payoutMethod.bank || 'N/A'}</span>
                </div>
              )}
              {!!payoutMethod?.account_name && (
                <div className="user-details-column">
                  <span className="user-details-column-label">
                    Account Name
                  </span>
                  <span>{payoutMethod.account_name || 'N/A'}</span>
                </div>
              )}
              {!!payoutMethod?.account_name && (
                <div className="user-details-column">
                  <span className="user-details-column-label">
                    Account Number
                  </span>
                  <span>
                    {payoutMethod.account_number
                      ? payoutMethod.account_number
                      : 'N/A'}
                  </span>
                </div>
              )}

              {payoutData.status === 'paid' && (
                <>
                  <div className="user-details-column">
                    <span className="user-details-column-label">
                      Reference Number
                    </span>
                    <span>
                      {payoutData.ref_number ? payoutData.ref_number : 'N/A'}
                    </span>
                  </div>

                  <div className="user-details-column">
                    <span className="user-details-column-label">Notes</span>
                    <span>
                      {payoutData.other_notes ? (
                        <ReactReadMoreReadLess
                          charLimit={150}
                          readMoreText={'Read more'}
                          readLessText={'Read less'}
                          readMoreClassName="read-more"
                          readLessClassName="read-less">
                          {payoutData.other_notes}
                        </ReactReadMoreReadLess>
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </div>
                </>
              )}
            </div>

            {payoutData.id && (
              <div className={classNames('payout-status', payoutData.status)}>
                <div className="status-wrapper">
                  <div className="status-label-wrapper">
                    <div className="status-indicator"></div>
                    <span className="payout-status-label">
                      {payoutData.status === 'paid'
                        ? 'Released'
                        : payoutData.status}
                    </span>
                  </div>
                  <span className="status-sub">Payout Status</span>

                  {payoutData.status === 'pending' && (
                    <button
                      className="release-payout-button"
                      onClick={handleOnReleasePayoutClick}>
                      Mark as Released
                    </button>
                  )}
                </div>

                <div className="payout-breakdown-container">
                  <div className="payout-breakdown-header">
                    <Icons.Summary className="summary-icon" />
                    <span className="payout-breakdown-title">
                      Payout Breakdown
                    </span>
                  </div>

                  <div className="payout-breakdown-item">
                    <span className="item-label">Gross Payout Amount</span>

                    <span className="item-value">
                      ₱
                      {(payoutData.total / 100).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>

                  <div className="payout-breakdown-item">
                    <span className="item-label">Transaction Fee</span>

                    <span className="item-value">
                      - ₱
                      {(transferFee || 0).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>

                  <div className="payout-breakdown-item">
                    <span className="item-label">Final Payout Amount</span>

                    <span className="item-value final-payout-amount">
                      ₱
                      {(
                        payoutData.total / 100 - transferFee || 0
                      ).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
          {payoutPosts.map(post => (
            <div key={post.id} className="section table-container">
              <div className="post-orders-item">
                <div className="post-orders-header">
                  <h3>{post.title}</h3>
                  <div className="post-orders-total-wrapper">
                    <span className="subtitle-2 color-icon">Amount</span>
                    <span className="post-orders-total">
                      ₱
                      {post.orders
                        .reduce(
                          (total, order) =>
                            total +
                            Math.trunc(
                              parseFloat(order.net_amount.toFixed(2)) * 100
                            ) /
                              100,
                          0
                        )

                        .toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                        })}
                    </span>
                  </div>
                </div>
                <DataTable
                  data={post.orders}
                  columns={ordersColumns}
                  noHeader={true}
                  pagination={true}
                  responsive={true}
                  fixedHeader={true}
                  customStyles={customStyles}
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        isOpen={isReleaseModalOpen}
        onRequestClose={() => setIsReleaseModalOpen(false)}
        style={modalStyles}
        contentLabel="Example Modal"
        className="release-payout-modal"
        overlayClassName="release-payout-modal-overlay">
        {!!payoutData.id && (
          <div className="release-payout-modal-content">
            <h2>Confirm Action</h2>
            <h5>To mark a payout as released, kindly confirm details</h5>

            <div className="release-payout-details">
              <div className="release-payout-details-content">
                <span>You are about to release the amount of</span>
                <span className="release-payout-amount">
                  ₱
                  {(payoutData.total / 100).toLocaleString('en-US', {
                    minimumFractionDigits: 2,
                  })}
                </span>
                {!!payoutData.user && (
                  <span>
                    to{' '}
                    <span className="seller-name">
                      {payoutData.user.full_name ||
                        payoutData.user.display_name}
                    </span>{' '}
                    via {payoutData.account_number} through{' '}
                    {payoutMethodLabels[payoutData.payout_method]}
                  </span>
                )}
              </div>
            </div>

            <div className="release-payout-inputs-container">
              <div className="release-payout-inputs-wrapper">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      maxLength={refNumberMaxLimit}
                      value={field.value}
                      onChange={field.onChange}
                      inputPlaceholder="Reference number"
                    />
                  )}
                  name={'refNumber'}
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <TextareaInput
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      inputPlaceholder="Notes (Optional)">
                      <div className="input-char-counter">
                        <span>{watchNotes.length}/100</span>
                      </div>
                    </TextareaInput>
                  )}
                  name={'otherNotes'}
                />
              </div>
            </div>

            <div className="release-payout-buttons-wrapper">
              <button
                onClick={handleSubmit(handleOnConfirmReleaseClick)}
                className="confirm-release-button"
                disabled={!isValid || isReleasing}>
                Yes, mark as released
              </button>
              <button
                onClick={() => setIsReleaseModalOpen(false)}
                className="cancel-button">
                Go Back
              </button>
            </div>
          </div>
        )}

        <button
          className="close-button"
          onClick={() => setIsReleaseModalOpen(false)}>
          <Icons.Close className="close-button-icon" />
        </button>
      </Modal>
    </>
  )
}

export default Payouts
