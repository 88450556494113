import React, { useCallback, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import Sidepanel from '../../layouts/Sidepanel'
import Api from '../../services/Api'
import moment from 'moment'
import pluralize from 'pluralize'
import Icons from '../../assets/icons'
import classNames from 'classnames'
import { useNavigate } from 'react-router'
import { format } from 'date-fns'

const Payouts = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [totalItems, setTotalItems] = useState(0)
  const [filters, setFilters] = useState({
    page: 0,
    limit: 10,
    sort: 'date_modified_desc',
    method: null,
    status: 'pending',
  })
  const [payouts, setPayouts] = useState([])
  const [stats, setStats] = useState({})
  const navigate = useNavigate()

  const payoutDate =
    moment().isoWeekday() <= 5
      ? moment().isoWeekday(5)
      : moment().add(1, 'week').isoWeekday(5)
  const columns = React.useMemo(
    () => [
      {
        name: 'Amount',
        selector: 'total',
        sortable: true,
        cell: row =>
          `₱${(row.total ? row.total / 100 : 0).toLocaleString('en-US', {
            minimumFractionDigits: 2,
          })}`,
      },
      {
        name: 'Method',
        selector: 'payout_method',
        cell: row => (row.payout_method ? row.payout_method : <span>N/A</span>),
      },
      {
        name: 'Account Name',
        selector: 'account_name',
        sortable: true,
        cell: row => (row.account_name ? row.account_name : <span>N/A</span>),
      },
      {
        name: 'Account Number',
        selector: 'account_number',
        cell: row =>
          row.account_number ? row.account_number : <span>N/A</span>,
      },
      {
        name: 'status',
        selector: 'status',
      },
      {
        name: 'Date Last Updated',
        selector: 'date_modified',
        sortable: true,
        cell: row =>
          row.date_modified ? (
            <span>
              {format(
                row.date_modified?._seconds * 1000,
                'MMM dd, yyyy hh:mm:ii'
              )}
            </span>
          ) : (
            <span>N/A</span>
          ),
        width: '180px',
      },
    ],
    []
  )

  const handleOnSort = (column, sort) => {
    setFilters(filters => ({ ...filters, sort: `${column.selector}_${sort}` }))
  }

  const handleOnChangeRowsPerPage = rowsPerPage => {
    setFilters(filters => ({ ...filters, limit: rowsPerPage }))
  }

  const handleOnChangePage = page => {
    setFilters(filters => ({ ...filters, page: page - 1 }))
  }

  const handleOnPayoutMethodClick = method => {
    setFilters(filters => ({ ...filters, method }))
  }

  const hanldeOnExportClick = async () => {
    try {
      const response = await fetch(`${Api.domain}/payouts/export/csv`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('token')}`,
        },
      })
      try {
        const jsonResponse = await response.body()
        if (!jsonResponse.success) throw new Error(jsonResponse.message)
      } catch {}
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'payouts.csv'
      document.body.appendChild(a)

      a.click()
      a.remove()
    } catch (error) {
      console.error(error)
    }
  }

  const handleOnRowClick = row => {
    if (!row.id) return
    navigate(`/admin/payouts/${row.id}`)
  }

  const fetchData = useCallback(async () => {
    setIsLoading(true)
    try {
      const { page, limit, sort, method, status } = filters

      const response = await Api.getPayouts({
        page,
        limit,
        sort,
        method,
        status,
      })

      if (!response.success) throw new Error(response.message)
      const { data, total_items } = response

      setPayouts(data)
      setTotalItems(total_items)
    } catch (error) {
      console.error(error)
    }
    setIsLoading(false)
  }, [filters])

  const fetchStats = useCallback(async () => {
    try {
      const response = await Api.getPayoutStats()
      setStats(response.data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  useEffect(() => {
    fetchStats()
  }, [fetchStats])

  const upcoming_payout = (
    parseFloat(isNaN(stats?.upcoming_payout) ? '0' : stats.upcoming_payout) /
    100
  ).toLocaleString('en', { maximumFractionDigits: 2 })

  return (
    <>
      <div className="payouts">
        <Sidepanel />
        <div className="payout-sidepanel">
          <h2>Upcoming payout</h2>
          <div className="upcoming-payout-wrapper">
            <span className="upcoming-payout-amount">₱{upcoming_payout}</span>
            <span className="upcoming-payot-label">Upcoming payout</span>
            <span className="paid-payouts-label">
              {pluralize('payment', stats?.orders_count || 0, true)}
            </span>
          </div>

          <div className="payout-date-wrapper">
            <div className="payout-date-container">
              <Icons.Calendar className="payout-date-icon" />
              <span className="payout-date">
                {payoutDate.format('MMMM D, yyyy')}
              </span>
            </div>
            <span className="payout-date-label">Upcoming payout schedule</span>
          </div>
        </div>
        <div className="payouts-content">
          <div className="datatable-wrapper">
            <div className="datatable-header">
              <div className="datatables-actions-wrapper">
                <button
                  className="export-button"
                  type="button"
                  onClick={hanldeOnExportClick}>
                  <Icons.Download className="export-icon" />
                  <span className="export-label">Download .CSV</span>
                </button>
              </div>
              <div className="payout-types">
                {/* Hide For Now */}
                {/* <div
                  className={classNames('payout-type', {
                    active: !filters.method,
                  })}
                  onClick={() => handleOnPayoutMethodClick(undefined)}>
                  <span>All</span>
                </div> */}
                <div
                  className={classNames('payout-type', {
                    active: filters.method === 'paymongo',
                  })}
                  onClick={() => handleOnPayoutMethodClick('paymongo')}>
                  <span>GCash/Bank Transfer</span>
                </div>
                {/* Hide For Now */}
                {/* <div
                  className={classNames('payout-type', {
                    active: filters.method === 'paypal',
                  })}
                  onClick={() => handleOnPayoutMethodClick('paypal')}>
                  <span>PayPal</span>
                </div> */}
              </div>
            </div>
            <DataTable
              data={payouts}
              columns={columns}
              noHeader={true}
              pagination={true}
              paginationTotalRows={totalItems}
              paginationServer={true}
              sortServer={true}
              onSort={handleOnSort}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              onChangeRowsPerPage={handleOnChangeRowsPerPage}
              onChangePage={handleOnChangePage}
              onRowClicked={handleOnRowClick}
              responsive={true}
              fixedHeader={true}
              progressPending={isLoading}
              defaultSortField={'date_modified'}
              defaultSortAsc={false}
              paginationComponentOptions={{
                rowsPerPageText: 'View',
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Payouts
