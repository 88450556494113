import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as UserProfile } from './user-profile.svg'
import { ReactComponent as LogoTagline } from './logo-tagline.svg'

const Images = {
  Logo,
  LogoTagline,
  UserProfile,
}

export default Images
