import React from 'react'

const Input = ({
  type,
  name,
  value,
  onChange,
  inputPlaceholder,
  children,
  maxLength,
  additionalClasses,
}) => {
  return (
    <div className="input-holder">
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        className={`input ${additionalClasses}`}
        maxLength={maxLength}
      />
      <span className="placeholder">{inputPlaceholder}</span>
      {children}
    </div>
  )
}

export default Input
