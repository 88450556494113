import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import { ContextProvider } from './context'

import { firebaseInit } from './utils/firebase'
import PrivateRoutes from './utils/PrivateRoutes'
import Dashboard from './pages/admin/Dashboard'
import Transactions from './pages/admin/transactions'
import TransactionDetails from './pages/admin/transaction-details'
import Payouts from './pages/admin/payouts'
import PayoutDetails from './pages/admin/payout-details'
import PayoutHistory from './pages/admin/payout-history'
import Notifications from './pages/admin/Notifications'
import RegisterRider from './pages/admin/RegisterRider'
import Login from './pages/admin/Login'
import PageNotFound from './pages/PageNotFound'

export const firebaseApp = firebaseInit()

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Router>
          <Routes>
            <Route element={<PrivateRoutes />}>
              <Route
                element={<Dashboard title="Verify users" />}
                path="/admin/dashboard"
                exact={false}></Route>
              <Route
                element={<Transactions title="Transactions" />}
                path="/admin/transactions"
                exact={false}></Route>
              <Route
                element={<TransactionDetails title="Transaction Details" />}
                path="/admin/transactions/:id"
                exact={false}></Route>
              <Route
                element={<Payouts title="Payouts" />}
                path="/admin/payouts"
                exact={false}></Route>
              <Route
                element={<PayoutDetails title="Payout Details" />}
                path="/admin/payouts/:id"
                exact={false}></Route>
              <Route
                element={<PayoutHistory title="Payout History" />}
                path="/admin/payout-history"
                exact={false}></Route>
              <Route
                element={<Notifications title="Notifications" />}
                path="/admin/notifications"
                exact={false}></Route>
              <Route
                element={<RegisterRider title="Register Rider" />}
                path="/admin/register-rider"
                exact={false}></Route>
            </Route>
            <Route element={<Login />} path="/login"></Route>
            <Route element={<PageNotFound />} path="*"></Route>
          </Routes>
        </Router>
      </ContextProvider>
    </div>
  )
}

export default App
