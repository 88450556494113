import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from '@react-google-maps/api'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import Sidepanel from '../../layouts/Sidepanel'
import Input from '../../components/Input'
import TextareaInput from '../../components/TextareaInput'
import Button from '../../components/Button'
import { debounce } from 'lodash'
import Modal from '../../layouts/Modal'
import Spinner from '../../components/Spinner'
import Api from '../../services/Api'
import Select from '../../components/Select'

const containerStyle = {
  width: '80vw',
  height: '60vh',
}

const validationSchema = yup
  .object()
  .shape({
    body: yup.string().optional(),
  })
  .required()

const Notifications = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  })

  const [radius, setRadius] = useState(3)
  const [center, setCenter] = useState({
    lat: 14.676208,
    lng: 121.043861,
  })
  const [previewVisible, setPreviewVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const circleRef = useRef()
  const mapRef = useRef()

  const { control, handleSubmit, watch, setValue } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: '',
      body: '',
      username: '',
      action: '',
    },
  })
  const { body = '', title = '', action = '', username = '' } = watch()

  const fitBounds = useCallback(
    debounce(() => {
      const bounds = circleRef.current.state?.circle.getBounds()
      if (!bounds) return
      mapRef.current.state.map.fitBounds(bounds)
    }, 300),
    []
  )

  const onLoad = React.useCallback(
    function callback(map) {
      fitBounds()
    },
    [fitBounds]
  )

  useEffect(() => {
    fitBounds()
  }, [radius, center, fitBounds])

  const onSubmit = async ({ title, body, action, username }) => {
    setIsLoading(true)
    try {
      const response = await Api.sendNotificationToUsersInLocation({
        body: {
          location: {
            latitude: center.lat,
            longitude: center.lng,
            radius: radius * 1000,
          },
          notification: {
            title,
            body,
          },
          action: {
            action,
            data: {
              username,
            },
          },
        },
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      setValue('body', '')
      setValue('title', '')
      setValue('username', '')
      alert('Success!')
    } catch (error) {
      console.log(error)
      alert('Oops! Something went wrong.')
    }

    setPreviewVisible(false)
    setIsLoading(false)
  }

  const canSubmit = useMemo(() => {
    return (
      !!body.length && !!title.length && !!action.length && !!username.length
    )
  }, [body.length, title.length, action, username])

  return (
    <>
      <div className="send-notification-wrapper">
        <div className="panel-wrapper">
          <Sidepanel />
        </div>
        <div className="content">
          {isLoaded && (
            <GoogleMap
              ref={mapRef}
              mapContainerStyle={containerStyle}
              center={center}
              options={{
                mapTypeControl: false,
                streetViewControl: false,
                clickableIcons: false,
              }}
              onClick={e => {
                setCenter({
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                })
              }}
              zoom={12}
              onLoad={onLoad}>
              <>
                <Marker
                  position={{
                    lat: center.lat,
                    lng: center.lng,
                  }}
                />
                <Circle
                  ref={circleRef}
                  center={center}
                  radius={radius * 1000}
                  options={{
                    strokeColor: 'rgba(255,212, 0)',
                    fillColor: 'rgb(255,212,0)',
                    fillOpacity: 0.4,
                    strokeOpacity: 0.4,
                    clickable: false,
                  }}
                />
              </>
            </GoogleMap>
          )}
          <div>
            <p>
              {radius < 1 ? `${radius * 1000} meters` : `${radius} kilometers`}
            </p>
            <Slider
              onChange={setRadius}
              value={radius}
              step={0.1}
              min={0.1}
              max={100}
            />
            <div>
              <div style={{ marginTop: '24px' }}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <>
                      <Input
                        type="text"
                        value={field.value}
                        onChange={field.onChange}
                        inputPlaceholder="Title"
                        maxLength={65}
                      />
                      <div className="content-length-wrapper">
                        <span>{title.length}/65</span>
                      </div>
                    </>
                  )}
                  name={'title'}
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <TextareaInput
                      type="text"
                      value={field.value}
                      onChange={field.onChange}
                      maxLength={150}
                      inputPlaceholder="Body">
                      <div className="content-length-wrapper">
                        <span>{body.length}/150</span>
                      </div>
                    </TextareaInput>
                  )}
                  name={'body'}
                />
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      onChange={field.onChange}
                      inputPlaceholder="Action"
                      options={[
                        {
                          label: 'Open profile',
                          value: 'profile',
                        },
                      ]}></Select>
                  )}
                  name={'action'}
                />

                {action === 'profile' && (
                  <>
                    <span className="input-label">Redirect to profile:</span>
                    <Controller
                      control={control}
                      render={({ field }) => (
                        <TextareaInput
                          type="text"
                          value={field.value}
                          onChange={field.onChange}
                          inputPlaceholder="Username"></TextareaInput>
                      )}
                      name={'username'}
                    />
                  </>
                )}
                <Button
                  className={`btn-yellow ${!canSubmit ? 'btn-disabled' : ''}`}
                  onClick={() => setPreviewVisible(true)}
                  disabled={!canSubmit}>
                  Send Notification
                </Button>
              </div>
            </div>
          </div>
        </div>
        {previewVisible && (
          <div>
            <Modal setViewModal={setPreviewVisible}>
              <div className="preview-wrapper">
                <div className="app-name-wrapper">
                  <div className="servbees-icon-wrapper">
                    <img src="/servbees-icon.png" alt="servbees-icon"></img>
                  </div>
                  <span className="app-name">Servbees • now</span>
                </div>
                <div className="preview-content">
                  <div className="push-notification-content">
                    <span className="title">{title}</span>
                    <span className="body">{body}</span>
                  </div>
                </div>

                <Button
                  className={`btn-yellow send-button ${
                    isLoading ? 'btn-disabled' : ''
                  }`}
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}>
                  {isLoading ? (
                    <Spinner
                      type="Oval"
                      color="#ffd400"
                      height={24}
                      width={24}
                    />
                  ) : (
                    'Send'
                  )}
                </Button>
              </div>
            </Modal>
          </div>
        )}
      </div>
    </>
  )
}

export default Notifications
